$basics-red: #dc4126;
$primary: #0447ff;

$theme-colors: (
    'basics-red': $basics-red,
    'basics-light-red': lighten($basics-red, 20%),
);

@import '../../node_modules/bootstrap/scss/bootstrap';
@import '../../node_modules/flatpickr/dist/flatpickr';
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,800&display=swap');

body,
html {
    font-family: 'Open Sans', sans-serif;
    height: 100%;
}

[data-braintree-id='toggle'] {
    background-color: white;
}

[data-braintree-id='toggle']:hover {
    background-color: white;
}

.input-button:hover {
    text-decoration: none;
}

.basics-logo {
    content: url('../assets/images/basics_logo.svg');
}

.card-button {
    opacity: 0.75;
    background-color: transparent;
}

.card-button-disabled {
    pointer-events: none;
    opacity: 0.5;
    background-color: transparent;
}

.card-button-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-decoration: none;
    color: white;
}

.card-button:hover {
    transform: translate(1px, 1px);
    opacity: 0.9;
}

.button-overlay {
    position: absolute;
    top: 50%;
    transform: translateY(-55%);
    background-color: black;
    opacity: 0.9;
    height: 40%;
}

.basics-logo-stroke {
    content: url('../assets/images/basics_logo_stroke.svg');
}

.infinity-black {
    content: url('../assets/images/infinity_black.svg');
}

.infinity-white {
    margin-bottom: 7px;
    content: url('../assets/images/infinity_white.svg');
}

.roman {
    font-family: 'Times New Roman, Times, serif';
    font-size: 1.5em;
}

.roman-sm {
    font-family: 'Times New Roman, Times, serif';
}

.UIBox-container {
    cursor: pointer;
}

.badge-pill {
    font-size: 0.9em;
}

.nav-tabs > a {
    color: $primary;
}
.nav-tabs > a:hover {
    color: darken($primary, 20%);
}

.nav-tabs > a.active {
    font-weight: 600;
}

.semi-bold {
    font-weight: 600;
}

.bold {
    font-weight: 800;
}

.unselectable {
    user-select: none;
}

.well {
    background: rgba(0, 0, 0, 0.02);
    border-radius: 10px;
    box-shadow: inset 0 0 2px black;
    padding: 5px;
}

// used in triad speller
.well-fixed {
    background: rgba(0, 0, 0, 0.02);
    border-radius: 10px;
    box-shadow: inset 0 0 2px black;
    height: 100px;
    padding-top: 9px;
}

.display-4 {
    font-size: 1.6rem;
}

@media (max-width: 768px) {
    h4 {
        font-size: 1.1rem;
    }
}

.btn-basics {
    color: white;
    background-color: $basics-red;
    border-color: $basics-red;
}

.btn-basics:hover {
    background-color: darken($basics-red, 10%);
    border-color: darken($basics-red, 10%);
    color: white;
}

.btn-basics:disabled {
    background-color: #ff6633;
    border-color: #ab1211;
}

.btn-basics:not(:disabled):not(.disabled):active {
    background-color: #af2124;
    border-color: #dc4126;
}

/* used for piano key overlay buttons */

.overlay-button:hover {
    fill: #bbb;
    fill-opacity: 0.5;
}

.overlay-button-disabled {
    fill: #ddd;
    fill-opacity: 0.4;
    pointer-events: none;
}

/* used for SVG custom buttons, ex. time signature buttons */
.custom-button-hover {
    fill: #555;
}

.custom-answer-button:hover {
    fill: #555;
    cursor: pointer;
}

.custom-button-disabled {
    pointer-events: none;
    opacity: 0.5;
}

.scale-button {
    transition-duration: 0.4s;
    min-width: 36px;
    margin-left: 2px;
    margin-right: 2px;
}

.scale-button-top {
    border-left: 1px solid darkslategrey;
    border-right: 1px solid darkslategrey;
    border-top: 1px solid darkslategrey;
    border-radius: 7px 7px 0px 0px;
}

.scale-button-middle {
    border: 1px solid darkslategrey;
    font-size: 1.4em;
    font-weight: 600;
    color: white;
}

.scale-button-bottom {
    border-left: 1px solid darkslategrey;
    border-right: 1px solid darkslategrey;
    border-bottom: 1px solid darkslategrey;
    border-radius: 0px 0px 7px 7px;
}

.scale-button:hover {
    border-color: darkslategrey;
}

.scale-button:active {
    background-color: #888;
}

.scale-button > svg > g {
    fill: white;
}
